import { axios, apiV1Url } from '@/api'

export const api = {
  getTendersActive() {
    return axios.get(`${apiV1Url}tenders/active`)
  },
  getLeaderBoard(tenderId: string) {
    return axios.get(`${apiV1Url}leaderboard/${tenderId}`)
  },
  getLeaderBoardLeagues(tenderId: string, leagueId: string) {
    return axios.get(`${apiV1Url}leaderboard/${tenderId}/league/${leagueId}`)
  },
}
