import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CSPIcons = _resolveComponent("CSPIcons")!

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass([`cp-icn cs-df-alc-jcc cp-icn--${_ctx.sizeCnIcon}`])
  }, [
    (_openBlock(), _createBlock(_component_CSPIcons, {
      key: _ctx.icon,
      "name-icon": _ctx.icon,
      style: _normalizeStyle([_ctx.iconStyles])
    }, null, 8, ["name-icon", "style"]))
  ], 2))
}