<template>
  <defaultLayout>
    <router-view />
  </defaultLayout>
</template>

<script lang="ts" setup>
import { onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { useTelegramWebApp } from '@/services/useTelegramWebApp'
import { authUser } from '@/helpers/auth/auth-user'
import { isCheckPlatform } from '@/helpers/defaultUtils'
import { validateTelegramUserInfo } from '@/helpers/validate'
import defaultLayout from '@/layouts/defaultLayout.vue'

const router = useRouter()
const { ready, platform, userInfo, startParam, authDate } = useTelegramWebApp()
const isDevelopment = process.env.NODE_ENV === 'development'

onBeforeMount(() => {
  ready()

  if (!isCheckPlatform(platform) && !isDevelopment) {
    router.push({ name: 'error', params: { code: 403 } })
    return
  }

  const telegramUserInfo = {
    id: userInfo?.id || 0,
    first_name: userInfo?.first_name || '',
    last_name: userInfo?.last_name || '',
    username: userInfo?.username || '',
    language_code: userInfo?.language_code || 'ru',
    auth_date: authDate,
  }

  // Приглашение
  const inviteID = startParam?.split('profileId-')[1]

  // // Если не пришла инфа от телеги, то перезагрузить страницу
  if (!telegramUserInfo?.id && !isDevelopment) {
    window.location.reload()
    return
  }

  const hashLD = validateTelegramUserInfo({
    id: telegramUserInfo?.id,
    first_name: telegramUserInfo?.first_name || '',
    last_name: telegramUserInfo?.last_name || '',
    username: telegramUserInfo?.username || '',
    language_code: telegramUserInfo?.language_code || 'ru',
  })

  const isUserAuth = authUser({ ...telegramUserInfo, hash: hashLD }, inviteID)

  if (!isUserAuth) {
    router.push({ name: 'error', params: { code: 401 } })
  }
})
</script>
