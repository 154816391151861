<template>
  <div class="cs-load">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div class="cs-load__t" v-html="t('main_loading.title')"></div>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div class="cs-load__d" v-html="t('main_loading.description')"></div>
    <div class="cs-load__anm">
      {{ t('main_loading.loading') }}<span>.</span><span>.</span><span>.</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useLocale } from '@/services/useLocale'

const { t } = useLocale()
</script>

<style lang="scss" scoped>
.cs-load {
  position: relative;
  padding: 64px 27px;
  min-height: 100vh;
  background-image: url('~@/assets/images/main/main-load.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &__t {
    color: rgba(255, 255, 255, 0.96);
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.192px;
    margin-bottom: 12px;
  }

  &__d {
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 133.333%;
  }

  &__anm {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 133.333%;
    letter-spacing: -0.018px;

    span {
      animation-name: blink;
      animation-duration: 1.4s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;

      &:nth-child(2) {
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
  }

  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
}
</style>
