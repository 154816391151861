export const NOTIFICATION_TASK_RESULT = {
  success: {
    nameIcon: 'NtSuccess',
    iconLabelOne: 'Badge',
    iconLabelTwo: 'Currency',
    iconLabelThree: 'Sparkles',
  },
  failed: {
    nameIcon: 'NtError',
    iconLabelOne: 'BadgeNegative',
    iconLabelTwo: 'CurrencyNegative',
    iconLabelThree: 'SparklesNegative',
  },
}
