import { FRIENDS_REWARD } from '@/helpers/const'

export default {
  main_loading: {
    title: 'Well Payed',
    description: 'Become the Top Earner <br> in Beastville',
    loading: 'Loading',
  },
  profile: {
    settings: {
      title: 'Settings',
      telegram: {
        title: 'Telegram ID',
      },
      lang: {
        title: 'Language',
      },
      support: {
        title: 'Report an Issue',
      },
      deleteAccount: {
        title: 'Delete an account',
      },
      popupDelete: {
        title: 'Are you sure you want to delete your account?',
        description:
          'All your data, including game progress, achievements, and purchases, will be permanently deleted. This action cannot be undone.',
        btnOneTitle: 'Delete Account',
        btnTwoTitle: 'Cancel',
      },
      notification: {
        copyTitle: 'Copied',
      },
    },
  },
  company: {
    firstOffer: {
      title: 'Received an offer in',
      description: '',
      bonusTitle: 'Company Stats',
      labelOneText: 'to experience',
      labelTwoText: 'for payment',
      btnTitleOne: 'Accept',
      btnTitleTwo: '',
      labelTenderText: 'Tender Participant',
    },
    secondOffer: {
      title: 'Received an offer in',
      description:
        'Accept the offer at $companyNames or continue your job search',
      bonusTitle: 'Company Stats',
      labelOneText: 'to experience',
      labelTwoText: 'for payment',
      btnTitleOne: 'Accept',
      btnTitleTwo: 'Continue searching',
      labelTenderText: 'Tender Participant',
    },
    list: {
      title: 'Companies',
      subTitle: 'All companies',
      btnTitle: 'Switch company',
    },
    paramsOffer: {
      experience: 'Experience',
      fee: 'Payout',
      effort: 'Paws',
      blockedExperience: 'No Career Growth',
      blockedEffort: 'Not in the Tender',
    },
  },
  notification: {
    startJobSearch: {
      title: 'Job Search Available',
      btnTitle: 'Start',
    },
    jobSearch: {
      title: 'Chance to find a job',
    },
    friends: {
      title: 'Multiply Your Bonus with Friends',
      btnTitle: 'Collect',
    },
  },
  tooltip: {
    'start-click-person': 'Tap on your character <br> to look for a job',
    'start-tsk-person': 'Tap on the character to start the task',
    'start-company': {
      title: 'Congratulations on your first <br>job!',
      description:
        'Now you work for <br> a company that will <br> send you tasks',
      btnTitle: 'Next',
      btnNext: 'start-task',
    },
    'start-task': {
      title: 'Receive tasks',
      description:
        'The company will send tasks, <br> for which you will receive <br> experience and payment',
      btnTitle: 'Next',
      btnNext: 'swipe-task',
    },
    'swipe-task': {
      title: 'Swipe left to dismiss',
      description: '',
      btnTitle: 'Next',
      btnNext: 'play-task',
    },
    // 'cancel-task': {
    //   title: 'Reject the task',
    //   description: '',
    //   btnTitle: 'Next',
    //   btnNext: 'play-task',
    // },
    'play-task': {
      title: 'Start execution',
      description:
        'Time to complete is limited. <br> You can reject a task <br> if it is not suitable for you',
      btnTitle: 'Next',
      btnNext: 'start-tsk-person',
    },
  },
  implement: {
    persons: {
      title: 'Person',
    },
    location: {
      title: 'Environment',
    },
    shop: {
      dog: 'Dogs',
      cat: 'Cats',
      location: 'Locations',
    },
  },
  skills: {
    title: 'Skills',
    efficiency: {
      title: 'Efficiency',
    },
  },
  mission: {
    title: 'Missions',
    daily: {
      title: 'Daily',
    },
    oneTime: {
      title: 'oneTime',
    },
    addTextMissionItem: 'tomorrow',
  },
  missionSuccess: {
    title: 'Mission Complete',
    desc: 'Log in every day to increase your reward',
    label: 'Log in to the game',
    totalCountText: 'days in a row',
    labelNow: {
      subText: 'Now',
    },
    labelTomorrow: {
      subText: 'Tomorrow',
    },
    btnTitle: 'Claim reward',
  },
  friends: {
    title: 'Multiply Your Bonus with Friends',
    description:
      'Invite friends, complete tasks together, and boost your bonus! One friend online — x2, two — x3, three — x4 and more!',
    btnTitle: 'Invite Friend',
    btnSubTitle: 'per online friend',
    titleList: 'Friends',
    subTxtList: 'online',
    btnTitleList: 'Call in',
    labelOnlineList: 'online',
  },
  friendsShare: {
    text: "Hey! Check out this new game. I think we can actually make some real money here, not like in Hamster. I'm already playing, join me!",
  },
  upLevelInfo: {
    title: 'Level Up!',
    btnTitle: 'Continue',
  },
  tender: {
    mainPage: {
      title: {
        active: 'Tender for',
        success: 'Tender Completed!',
        failed: 'Tender Failed :(',
      },
      description: {
        active:
          'Beastville&rsquo;s streets have turned into an&nbsp;ice rink, and tails are breaking left and right! City Hall is&nbsp;in&nbsp;panic&mdash;urgently seeking de-icers that keep paws soft and bones unbroken.',
        success:
          'Beastville is&nbsp;proud of&nbsp;you! Thanks to&nbsp;your efforts, we&nbsp;achieved incredible results. The top contributors will receive well-deserved rewards!',
        failed:
          'Unfortunately, you didn&rsquo;t contribute enough. The mayor of&nbsp;Beastville is&nbsp;disappointed but believes in&nbsp;your future success. Stay tuned&mdash;new tenders are on&nbsp;the way!',
      },
      btnTitle: 'Close',
      btnNav: {
        btnTitleOne: 'Rules',
        btnTitleTwo: 'To the tasks',
      },
    },
    offert: {
      btnTitle: 'To the tasks',
      contentHtml:
        '  <div class="cs-mb-20">\n' +
        '    <h2>General Conditions:</h2>\n' +
        '    <ul>\n' +
        '      <li>\n' +
        '        All players automatically contribute to&nbsp;the tender\n' +
        '        by&nbsp;completing tasks on&nbsp;the main game screen.\n' +
        '      </li>\n' +
        '      <li>\n' +
        '        By&nbsp;completing a&nbsp;task, a&nbsp;player contributes to&nbsp;the\n' +
        '        overall progress of&nbsp;the tender.\n' +
        '      </li>\n' +
        '      <li>\n' +
        '        The contribution size depends on&nbsp;the player&rsquo;s level and the\n' +
        '        company they work for. Level&nbsp;up, choose the optimal company,\n' +
        '        complete tasks, and earn prizes\n' +
        '      </li>\n' +
        '    </ul>\n' +
        '  </div>\n' +
        '  <div class="cs-mb-20">\n' +
        '    <h2 class="cs-mb-16">Penalties for Unfair Play:</h2>\n' +
        '    <p>Well Payed only allows fair play. We&nbsp;track violations using multiple parameters. If&nbsp;a&nbsp;player is&nbsp;caught using unfair methods:</p>\n' +
        '    <ul>\n' +
        '      <li>\n' +
        '        First warning&nbsp;&mdash; complete reset of&nbsp;the tender contribution and in-game progress.\n' +
        '      </li>\n' +
        '      <li>\n' +
        '        Repeat offense&nbsp;&mdash; permanent banishment from Beastville in&nbsp;disgrace.\n' +
        '      </li>\n' +
        '    </ul>\n' +
        '    <p>You have a&nbsp;chance to&nbsp;win a&nbsp;real prize! Don&rsquo;t risk it&nbsp;&mdash; breaking the rules will cost you this opportunity!</p>\n' +
        '  </div>\n' +
        '  <div class="cs-mb-20">\n' +
        '    <h2>Prize Pool and Placements:</h2>\n' +
        '    <ul>\n' +
        '      <li>\n' +
        '        The tournament prize pool is&nbsp;10,000&nbsp;₽ and is&nbsp;distributed among the winners of&nbsp;two leagues:\n' +
        '        <p style="margin-top: 8px">Beginner League:</p>\n' +
        '        <ul style="margin-top: 8px">\n' +
        '          <li>1st place&nbsp;&mdash; 1500&nbsp;₽</li>\n' +
        '          <li>2nd place&nbsp;&mdash; 1000&nbsp;₽</li>\n' +
        '          <li>3rd place&nbsp;&mdash; 500&nbsp;₽</li>\n' +
        '        </ul>\n' +
        '        <p style="margin-top: 8px">Specialist League:</p>\n' +
        '        <ul style="margin-top: 8px">\n' +
        '          <li>1st place&nbsp;&mdash; 3000&nbsp;₽</li>\n' +
        '          <li>2nd place&nbsp;&mdash; 2000&nbsp;₽</li>\n' +
        '          <li>3rd place&nbsp;&mdash; 1000&nbsp;₽</li>\n' +
        '          <li>4th place&nbsp;&mdash; 500&nbsp;₽</li>\n' +
        '          <li>5th place&nbsp;&mdash; 500&nbsp;₽</li>\n' +
        '        </ul>\n' +
        '      </li>\n' +
        '      <li>\n' +
        '        Prizes will be&nbsp;provided in&nbsp;the form of&nbsp;OZON gift\n' +
        '        certificates with the corresponding values.\n' +
        '      </li>\n' +
        '    </ul>\n' +
        '  </div>\n' +
        '  <div class="cs-mb-20">\n' +
        '    <h2>Determining the Winners:</h2>\n' +
        '    <ul>\n' +
        '      <li>Winners are determined when the tender ends.</li>\n' +
        '      <li>After the set time for completing the tasks has passed.</li>\n' +
        '      <li>Or&nbsp;earlier if&nbsp;the tender reaches 100% completion.</li>\n' +
        '    </ul>\n' +
        '  </div>\n' +
        '  <div class="cs-mb-20">\n' +
        '    <h2>Announcement of&nbsp;Results:</h2>\n' +
        '    <ul>\n' +
        '      <li>\n' +
        '        The list of&nbsp;winners will be&nbsp;published in&nbsp;the official\n' +
        '        game channel.\n' +
        '      </li>\n' +
        '      <li>\n' +
        '        We&nbsp;will contact each winner via Telegram to&nbsp;deliver the prize.\n' +
        '      </li>\n' +
        '    </ul>\n' +
        '  </div>',
    },
    list: {
      title: {
        active: 'Leaders',
        success: 'Winners',
      },
    },
    progress: {
      title: 'Progress',
      timer: {
        days: 'd',
        hours: 'h',
        minutes: 'm',
        seconds: 's',
      },
    },
    mainInfo: {
      name: 'Tender for',
      position: 'Your position',
      result: 'Results',
    },
  },
}
