import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ms-sc cs-h100"
}
const _hoisted_2 = { class: "ms-sc__img" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "ms-sc__t" }
const _hoisted_5 = { class: "ms-sc__d" }
const _hoisted_6 = { class: "ms-sc__bl cs-mb-12" }
const _hoisted_7 = { class: "ms-sc__lb" }
const _hoisted_8 = { class: "ms-sc__ds" }
const _hoisted_9 = { class: "ms-sc__bl" }

import { computed, PropType } from 'vue'
import { useLocale } from '@/services/useLocale'
import useCurrentInstance from '@/composable/useCurrentInstance'
import { missionApi } from '@/api'
import { User } from '@/types/user/User'
import { DailyLoginMission } from '@/types/mission/DailyLoginMission'

import ViewMobileContainer from '@/components/ui/container/ViewMobileContainer.vue'
import LabelCount from '@/components/ui/label-info/LabelCount.vue'
import LabelInfo from '@/components/ui/label-info/LabelInfo.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'MissionSuccess',
  props: {
  missionInfo: {
    type: Object as PropType<DailyLoginMission>,
    default: null,
  },
},
  emits: ["reward-mission"],
  setup(__props, { emit: __emit }) {

const props = __props

const emit = __emit

const { t } = useLocale()
const { proxy } = useCurrentInstance()

const missionSuccessImg = computed(() =>
  require('@/assets/images/mission/mission-success.png'),
)

const rewardMission = async () => {
  try {
    const {
      data: { profile },
    } = await missionApi.rewardMission(props.missionInfo?.id)
    emit('reward-mission', profile)
  } catch (error) {
    proxy.$alertNotification('ui-error', null, {
      title: 'Ошибка, повторите позже',
    })
    console.log(error)
  }
}

return (_ctx: any,_cache: any) => {
  const _component_BaseBtn = _resolveComponent("BaseBtn")!

  return (__props.missionInfo?.reward?.today)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(ViewMobileContainer, {
          "is-visible-btn": "",
          "btn-position-sticky": "",
          "is-scroll-wrapper": ""
        }, {
          "content-container": _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                src: missionSuccessImg.value,
                alt: ""
              }, null, 8, _hoisted_3)
            ]),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(t)(`missionSuccess.title`)), 1),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(t)(`missionSuccess.desc`)), 1),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(t)(`missionSuccess.label`)), 1),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(LabelCount, {
                  mode: "ms",
                  "current-value": __props.missionInfo?.days?.out || 0,
                  "total-value": `${__props.missionInfo?.days?.total || 0} ${_unref(t)(`missionSuccess.totalCountText`)}`
                }, null, 8, ["current-value", "total-value"])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(LabelInfo, {
                mode: "ms-success-now",
                "name-icon": "Currency",
                text: `+${__props.missionInfo?.reward?.today || 0}`,
                "sub-text": _unref(t)(`missionSuccess.labelNow.subText`)
              }, null, 8, ["text", "sub-text"]),
              _createVNode(LabelInfo, {
                mode: "ms-success-tmr",
                "name-icon": "Currency",
                text: `+${__props.missionInfo?.reward?.tomorrow || 0}`,
                "sub-text": _unref(t)(`missionSuccess.labelTomorrow.subText`)
              }, null, 8, ["text", "sub-text"])
            ])
          ]),
          "btn-container": _withCtx(() => [
            _createVNode(_component_BaseBtn, {
              icon: "Currency",
              "is-icon-container": "",
              w100: "",
              onClick: rewardMission
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)(`missionSuccess.btnTitle`)), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}
}

})