<template>
  <span :class="[`cp-icn cs-df-alc-jcc cp-icn--${sizeCnIcon}`]">
    <CSPIcons :key="icon" :name-icon="icon" :style="[iconStyles]" />
  </span>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'BaseIconContainer',
  props: {
    icon: {
      type: String,
      default: '',
    },
    sizeCnIcon: {
      type: String,
      default: 'middle',
    },
    widthIcon: {
      type: String,
      default: '',
    },
    heightIcon: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const iconStyles = computed(() => {
      return {
        width: props.widthIcon || null,
        height: props.heightIcon || null,
      }
    })

    return {
      iconStyles,
    }
  },
})
</script>

<style lang="scss" scoped>
.cp-icn {
  width: var(--v-btn-cnt-width);
  min-width: var(--v-btn-cnt-width);
  height: var(--v-btn-cnt-height);
  margin: 0 2px;

  &--small {
    --v-btn-cnt-width: 16px;
    --v-btn-cnt-height: 16px;
  }
  &--middle {
    --v-btn-cnt-width: 20px;
    --v-btn-cnt-height: 20px;
  }
  &--large {
    --v-btn-cnt-width: 24px;
    --v-btn-cnt-height: 24px;
  }
}
</style>
