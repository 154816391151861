import { useUserProfileStore } from '@/stores/user'
// import { useLogger } from 'vue-logger-plugin'
import { axios, usersApi } from '@/api/index'
import { User } from '@/types/user/User'
import { UserAuth } from '@/types/user/UserAuth'
import { setJWT, getJWT } from '@/helpers/get-jwt'
import { useLocale } from '@/services/useLocale'

const isUserAuth = (user: User | null) => {
  return Boolean(user?.id)
}

export async function authUser(telegramUserInfo: UserAuth, inviteID?: string) {
  const userProfileStore = useUserProfileStore()
  // const log = useLogger()
  const { setLocale } = useLocale()

  let userInfo: User | null = userProfileStore.user

  function setUserInfo(userResponse: User | null) {
    userInfo = userResponse
    userProfileStore.setUser(userInfo)
    if (userResponse?.languageCode) {
      setLocale(userResponse.languageCode)
    }
  }

  async function getUser() {
    const {
      data: { profile },
    } = await usersApi.getMe()
    return profile
  }

  async function userLogin() {
    try {
      const { data: authInfo } = await usersApi.userAuth(
        telegramUserInfo,
        inviteID,
      )
      getToken(authInfo.token)

      const profile = await getUser()
      setUserInfo(profile)
    } catch (error) {
      console.log(error)
      // log.info('auth/login post info', telegramUserInfo)
      // log.error('auth/login error', error)
    }
  }

  function getToken(token: string) {
    setJWT(token)
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }

  const tokenAuth = getJWT().accessToken

  if (tokenAuth) {
    getToken(getJWT()?.accessToken || '')
  }

  // Нет инфы об юзере и есть токен - запрос на профиль
  if (!userInfo && tokenAuth) {
    try {
      const profile = await getUser()
      // Нет инфы профиля - запрос на авторизацию
      // log.info('players/profile get info', profile)
      if (!profile) {
        await userLogin()
        return
      }
      setUserInfo(profile)
    } catch (error) {
      console.log(error)
      // log.error('players/profile error', error)
      // Ошибка на получение профиля - запрос на авторизацию
      await userLogin()
    }
  } else if (!userInfo && !tokenAuth) {
    // Нет инфы об юзере и нет токена - запрос на авторизацию
    await userLogin()
  }

  return isUserAuth(userInfo)
}
