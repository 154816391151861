import { FRIENDS_REWARD } from '@/helpers/const'

export default {
  main_loading: {
    title: 'Well Payed',
    description: 'Стань самым успешным <br> в Биствилле',
    loading: 'Загрузка',
  },
  profile: {
    settings: {
      title: 'Настройки',
      telegram: {
        title: 'Telegram ID',
      },
      lang: {
        title: 'Язык',
      },
      support: {
        title: 'Сообщить о проблеме',
      },
      deleteAccount: {
        title: 'Удалить аккаунт',
      },
      popupDelete: {
        title: 'Вы уверены, что хотите удалить аккаунт?',
        description:
          'Ваши данные, включая прогресс в игре, достижения и покупки, будут безвозвратно удалены. Это действие нельзя отменить.',
        btnOneTitle: 'Удалить аккаунт',
        btnTwoTitle: 'Отменить',
      },
      notification: {
        copyTitle: 'Cкопировано',
      },
    },
  },
  company: {
    firstOffer: {
      title: 'Получен оффер в',
      description: '',
      bonusTitle: 'Параметры компании',
      labelOneText: 'к опыту',
      labelTwoText: 'к оплате',
      btnTitleOne: 'Принять',
      btnTitleTwo: '',
      labelTenderText: 'Участник тендера',
    },
    secondOffer: {
      title: 'Получен оффер в',
      description: 'Примите оффер в $companyNames или продолжите поиск работы',
      bonusTitle: 'Параметры компании',
      labelOneText: 'к опыту',
      labelTwoText: 'к оплате',
      btnTitleOne: 'Принять',
      btnTitleTwo: 'Продолжить поиск',
      labelTenderText: 'Участник тендера',
    },
    list: {
      title: 'Компании',
      subTitle: 'Все компании',
      btnTitle: 'Сменить компанию',
    },
    paramsOffer: {
      experience: 'Опыт',
      fee: 'Оплата',
      effort: 'Лапки',
      blockedExperience: 'Нет развития',
      blockedEffort: 'Не участвует в тендере',
    },
  },
  notification: {
    startJobSearch: {
      title: 'Доступен поиск <br> новой работы',
      btnTitle: 'Начать',
    },
    jobSearch: {
      title: 'Шанс найти работу',
    },
    friends: {
      title: 'Доход от друзей',
      btnTitle: 'Забрать',
    },
  },
  tooltip: {
    'start-click-person': 'Тапай на персонажа, <br> чтоб искать работу',
    'start-tsk-person':
      'Тапайте на персонажа, чтобы приступить <br> к выполнению задания',
    'start-company': {
      title: 'Поздравляем с первой <br> работой!',
      description:
        'Теперь вы работаете в <br> компании, которая будет <br> присылать вам задачи',
      btnTitle: 'Далее',
      btnNext: 'start-task',
    },
    'start-task': {
      title: 'Получайте задачи',
      description:
        'Компания будет присылать задачи, <br> за выполнение которых вы получите <br> опыт и оплату',
      btnTitle: 'Далее',
      btnNext: 'swipe-task',
    },
    'swipe-task': {
      title: 'Свайп влево, чтоб отклонить',
      description: '',
      btnTitle: 'Далее',
      btnNext: 'play-task',
    },
    // 'cancel-task': {
    //   title: 'Отклоните задачу',
    //   description: '',
    //   btnTitle: 'Далее',
    //   btnNext: 'play-task',
    // },
    'play-task': {
      title: 'Начните выполнение',
      description:
        'Время на выполнение ограничено. <br> Вы можете отклонить задачу, <br> если она вам не подходит',
      btnTitle: 'Далее',
      btnNext: 'start-tsk-person',
    },
  },
  implement: {
    persons: {
      title: 'Персонажи',
    },
    location: {
      title: 'Окружение',
    },
    shop: {
      dog: 'Собаки',
      cat: 'Коты',
      location: 'Окружение',
    },
  },
  skills: {
    title: 'Навыки',
    efficiency: {
      title: 'Эффективность',
    },
  },
  mission: {
    title: 'Миссии',
    daily: {
      title: 'Ежедневные',
    },
    oneTime: {
      title: 'Разовые',
    },
    addTextMissionItem: 'завтра',
  },
  missionSuccess: {
    title: 'Миссия выполнена',
    desc: 'Заходите в игру каждый день, чтобы увеличивать свою награду',
    label: 'Войти в игру',
    totalCountText: 'дней подряд',
    labelNow: {
      subText: 'Сейчас',
    },
    labelTomorrow: {
      subText: 'Завтра',
    },
    btnTitle: 'Забрать награду',
  },
  friends: {
    title: 'Умножай бонус за счёт друзей',
    description:
      'Приглашай друзей, выполняйте таски вместе и увеличивай бонус! Один друг онлайн — x2, два — x3, три — x4 и выше!',
    btnTitle: 'Пригласить друга',
    btnSubTitle: '/за друга онлайн',
    titleList: 'Друзья',
    subTxtList: 'онлайн',
    btnTitleList: 'Позвать',
    labelOnlineList: 'онлайн',
  },
  friendsShare: {
    text: 'Привет! Зацени новую игру. Думаю там реально можно заработать, не то что в Хомяке. Я уже играю, погнали со мной',
  },
  upLevelInfo: {
    title: 'Уровень повышен!',
    btnTitle: 'Продолжить',
  },
  tender: {
    mainPage: {
      title: {
        active: 'Тендер на',
        success: 'Тендер завершён!',
        failed: 'Тендер провален :(',
      },
      description: {
        active:
          'Жители Биствилля скользят, как на&nbsp;катке, и&nbsp;ломают хвосты! Мэрия в&nbsp;панике&nbsp;&mdash; срочно нужны реагенты, которые спасут горожан и&nbsp;не&nbsp;повредят их&nbsp;нежным лапкам.',
        success:
          'Биствилль гордится вами! Благодаря вашему вкладу мы&nbsp;достигли невероятных результатов. Лучшие участники получат заслуженные награды!',
        failed:
          'К&nbsp;сожалению, вы&nbsp;не&nbsp;внесли достаточный вклад. Мэр Биствилля разочарован, но&nbsp;верит в&nbsp;ваши будущие успехи. Новые тендеры уже на&nbsp;подходе!',
      },
      btnTitle: 'Закрыть',
      btnNav: {
        btnTitleOne: 'Правила',
        btnTitleTwo: 'К задачам',
      },
    },
    offert: {
      btnTitle: 'К задачам',
      contentHtml:
        '  <div class="cs-mb-20">\n' +
        '    <h2>Общие условия:</h2>\n' +
        '    <ul>\n' +
        '      <li>\n' +
        '        Все игроки автоматически вносят вклад в&nbsp;тендер, выполняя задачи\n' +
        '        на&nbsp;главном экране игры.\n' +
        '      </li>\n' +
        '      <li>\n' +
        '        Выполняя задачу, игрок вносит вклад в&nbsp;общий прогресс тендера.\n' +
        '      </li>\n' +
        '      <li>\n' +
        '        Размер вклада зависит от&nbsp;уровня игрока и&nbsp;компании,\n' +
        '        в&nbsp;которой он&nbsp;работает. Прокачивайте уровень, выбирайте\n' +
        '        оптимальную компанию, выполняйте задачи и&nbsp;получайте призы.\n' +
        '      </li>\n' +
        '    </ul>\n' +
        '  </div>\n' +
        '  <div class="cs-mb-20">\n' +
        '    <h2 class="cs-mb-16">Наказание за&nbsp;нечестные методы:</h2>\n' +
        '    <p>В&nbsp;Well Payed допускается только честная игра. Мы&nbsp;отслеживаем нарушения по&nbsp;ряду параметров. Если игрок будет уличён в&nbsp;нечестных методах:</p>\n' +
        '    <ul>\n' +
        '      <li>\n' +
        '        Первое предупреждение&nbsp;&mdash; полный сброс вклада в&nbsp;тендер и&nbsp;обнуление внутриигрового прогресса.\n' +
        '      </li>\n' +
        '      <li>\n' +
        '        Повторное нарушение&nbsp;&mdash; пожизненное изгнание из&nbsp;Биствилля с&nbsp;позором.\n' +
        '      </li>\n' +
        '    </ul>\n' +
        '    <p>У&nbsp;вас есть шанс получить реальный приз! Не&nbsp;рискуйте&nbsp;&mdash; нарушение правил будет стоить вам этой возможности!</p>\n' +
        '  </div>\n' +
        '  <div class="cs-mb-20">\n' +
        '    <h2>Призовой фонд и&nbsp;места:</h2>\n' +
        '    <ul>\n' +
        '      <li>\n' +
        '        Призовой фонд турнира составляет 10&nbsp;000&nbsp;₽ и&nbsp;распределяется между победителями двух лиг:\n' +
        '        <p style="margin-top: 8px">Лига новичков:</p>\n' +
        '        <ul style="margin-top: 8px">\n' +
        '          <li>1&nbsp;место&nbsp;&mdash; 1500&nbsp;₽</li>\n' +
        '          <li>2&nbsp;место&nbsp;&mdash; 1000&nbsp;₽</li>\n' +
        '          <li>3&nbsp;место&nbsp;&mdash; 500&nbsp;₽</li>\n' +
        '        </ul>\n' +
        '        <p style="margin-top: 8px">Лига специалистов:</p>\n' +
        '        <ul style="margin-top: 8px">\n' +
        '          <li>1&nbsp;место&nbsp;&mdash; 3000&nbsp;₽</li>\n' +
        '          <li>2&nbsp;место&nbsp;&mdash; 2000&nbsp;₽</li>\n' +
        '          <li>3&nbsp;место&nbsp;&mdash; 1000&nbsp;₽</li>\n' +
        '          <li>4&nbsp;место&nbsp;&mdash; 500&nbsp;₽</li>\n' +
        '          <li>5&nbsp;место&nbsp;&mdash; 500&nbsp;₽</li>\n' +
        '        </ul>\n' +
        '      </li>\n' +
        '      <li>\n' +
        '        Призы предоставляются в&nbsp;виде сертификатов OZON\n' +
        '        с&nbsp;соответствующими номиналами.\n' +
        '      </li>\n' +
        '    </ul>\n' +
        '  </div>\n' +
        '  <div class="cs-mb-20">\n' +
        '    <h2>Определение победителей:</h2>\n' +
        '    <ul>\n' +
        '      <li>Победители определяются по&nbsp;завершении тендера.</li>\n' +
        '      <li>После окончания времени, отведенного на&nbsp;выполнение.</li>\n' +
        '      <li>Досрочно, если тендер завершится на&nbsp;100%.</li>\n' +
        '    </ul>\n' +
        '  </div>\n' +
        '  <div class="cs-mb-20">\n' +
        '    <h2>Объявление результатов:</h2>\n' +
        '    <ul>\n' +
        '      <li>Список победителей публикуется в&nbsp;официальном канале игры.</li>\n' +
        '      <li>\n' +
        '        Мы&nbsp;свяжемся с&nbsp;каждым победителем в&nbsp;Telegram для передачи\n' +
        '        приза.\n' +
        '      </li>\n' +
        '    </ul>\n' +
        '  </div>',
    },
    list: {
      title: {
        active: 'Лидеры',
        success: 'Победители',
      },
    },
    progress: {
      title: 'Прогресс',
      timer: {
        days: 'д',
        hours: 'ч',
        minutes: 'м',
        seconds: 'с',
      },
    },
    mainInfo: {
      name: 'Тендер на',
      position: 'Ваше место',
      result: 'Результаты',
    },
  },
}
