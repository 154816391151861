import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

import LoaderBtn from '@/components/ui/LoaderBtn.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'SBtn',
  props: {
  be: {
    type: String,
    default: 'button',
  },
  mode: {
    type: String,
    default: 'primary',
  },
  size: {
    type: String,
    default: 'small',
  },
  icon: {
    type: String,
    default: '',
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_CSPIcons = _resolveComponent("CSPIcons")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(__props.be), _mergeProps({
    class: [`s-btn cs-df-alc-jcc s-btn--${__props.mode} s-btn--${__props.size}`]
  }, _ctx.$attrs), {
    default: _withCtx(() => [
      (__props.icon && !__props.isLoading)
        ? (_openBlock(), _createBlock(_component_CSPIcons, {
            key: 0,
            "name-icon": __props.icon
          }, null, 8, ["name-icon"]))
        : (__props.isLoading)
          ? (_openBlock(), _createBlock(LoaderBtn, { key: 1 }))
          : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16, ["class"]))
}
}

})