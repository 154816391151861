<template>
  <div class="cs-app" :style="{ '--cs-tg-height': `${getViewportHeight()}px` }">
    <MainLoading v-if="userProfileStore.isMainLoading" />

    <router-view v-if="!userProfileStore.isMainLoading" />

    <CSNotification ref="notificationRef" group="notificationGroup" />

    <CSPopup
      v-if="popupToShow === 'popupMission'"
      mode="mobile"
      max-width="480px"
      not-padding
      disable-on-click-outside
      @close="hidePopup"
    >
      <template #body>
        <MissionSuccess
          :mission-info="
            userProfileStore?.user?.events?.missions?.dailyLoginMission
          "
          @reward-mission="rewardMission($event)"
        />
      </template>
    </CSPopup>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, watch } from 'vue'
import { useTitle } from '@vueuse/core'
import { useTelegramWebApp } from '@/services/useTelegramWebApp'
import { useUserProfileStore } from '@/stores/user'
import usePopupToShow from '@/composable/usePopupToShow'

import { User } from '@/types/user/User'

import MainLoading from '@/components/main/MainLoading.vue'
import CSNotification from '@/components/base/notification/CSNotification.vue'
import CSPopup from '@/components/base/popup/CSPopup.vue'
import MissionSuccess from '@/components/mission/MissionSuccess.vue'

useTitle('Well Payed')
const { getViewportHeight } = useTelegramWebApp()
const { popupToShow, openPopup, hidePopup } = usePopupToShow()
const userProfileStore = useUserProfileStore()

const rewardMission = (userInfo: User) => {
  userProfileStore.setUser(userInfo)
  hidePopup()
}

const isLoad = () => {
  setTimeout(() => {
    userProfileStore.isMainLoading = false
  }, 3000)
}

onMounted(() => {
  isLoad()
})

watch(
  () => userProfileStore?.user?.events?.missions?.dailyLoginMission,
  (value) => {
    if (
      value?.reward?.today &&
      !userProfileStore?.user?.isJobSearch &&
      !userProfileStore?.user?.events?.friendEarnings?.amount &&
      !userProfileStore?.user?.events?.passiveIncome?.amount
    ) {
      setTimeout(() => {
        if (userProfileStore.currentPopupToShow) return
        openPopup('popupMission')
      }, 4000)
    }
  },
)
</script>

<style lang="scss" scoped>
.cs-app {
  display: flex;
  flex-direction: column;
  //min-height: 100vh;
  height: var(--cs-tg-height);
  max-width: 460px;
  margin: 0 auto;

  * {
    font-family: Inter, sans-serif, serif;
    font-feature-settings: 'zero' on;
  }
}
</style>
