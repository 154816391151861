<template>
  <span class="lbi" :class="[`lbi--${mode}`, { 'lbi--blocked': isBlocked }]">
    <CSPIcons v-if="nameIcon" :name-icon="nameIconPrepared" class="lbi__icon" />
    <span v-if="imgSrc" class="lbi__img cs-df-alc-jcc">
      <img :src="imgSrc" alt="" />
    </span>
    <span v-if="!isBlocked" class="lbi__bl">
      <span v-if="subText" class="lbi__stxt txt-ovh-1line-clamp">
        {{ subText }}
      </span>
      <span class="lbi__txt txt-ovh-1line-clamp">
        {{ text }}
        <span v-if="addText" class="sm-tx">{{ addText }}</span>
      </span>
    </span>
    <CSPIcons v-else name-icon="Blocked" class="lbi__icon-blc" />
  </span>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps({
  mode: {
    type: String,
    default: 'df',
  },
  nameIcon: {
    type: String,
    default: '',
  },
  text: {
    type: [String, Number],
    default: '',
  },
  addText: {
    type: [String, Number],
    default: '',
  },
  subText: {
    type: [String, Number],
    default: '',
  },
  imgSrc: {
    type: String,
    default: '',
  },
  isBlocked: {
    type: Boolean,
    default: false,
  },
})

const nameIconPrepared = computed(() =>
  props.isBlocked ? `${props.nameIcon}Negative` : props.nameIcon,
)
</script>

<style lang="scss" scoped>
.lbi {
  display: grid;
  grid-template-columns: var(--v-grid-template-columns);
  align-items: center;
  max-width: fit-content;
  grid-gap: var(--v-grid-gap);
  padding: var(--v-padding);
  border-radius: 999px;
  background: rgba(255, 255, 255, 0.1);
  //backdrop-filter: blur(calc(30px / 2));

  &--blocked {
    background: rgba(255, 136, 0, 0.3);
  }

  &__icon-blc {
    color: #ff8800;
  }

  &__icon {
    width: var(--v-width-icon);
    min-width: var(--v-width-icon);
    max-width: var(--v-width-icon);
    height: var(--v-height-icon);
  }

  &__img {
    width: var(--v-width-icon);
    min-width: var(--v-width-icon);
    max-width: var(--v-width-icon);
    height: var(--v-height-icon);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__txt {
    color: var(--base-text-color-white);
    font-size: var(--v-font-size);
    line-height: var(--v-line-height);

    .sm-tx {
      font-size: var(--v-font-size);
      line-height: var(--v-line-height);
      color: rgba(255, 255, 255, 0.5);
    }
  }

  &__stxt {
    color: rgba(255, 255, 255, 0.5);
    font-size: var(--v-font-size-sbtx);
    line-height: var(--v-line-height-sbtx);
  }

  // mode
  &--df,
  &--success {
    --v-grid-template-columns: 24px 1fr;
    --v-grid-gap: 6px;
    --v-padding: 8px 12px 8px 8px;

    --v-width-icon: 24px;
    --v-height-icon: 24px;

    --v-font-size: 16px;
    --v-line-height: 150%;
  }

  &--profile {
    max-width: fit-content;

    --v-grid-template-columns: 16px 1fr;
    --v-grid-gap: 4px;
    --v-padding: 2px 6px 2px 4px;

    --v-width-icon: 16px;
    --v-height-icon: 16px;

    --v-font-size: 14px;
    --v-line-height: 142%;
  }

  &--failed {
    --v-grid-template-columns: 24px 1fr;
    --v-grid-gap: 6px;
    --v-padding: 8px 12px 8px 8px;

    --v-width-icon: 24px;
    --v-height-icon: 24px;

    --v-font-size: 16px;
    --v-line-height: 150%;

    opacity: 0.5;

    .lbi__txt {
      text-decoration: line-through;
    }
  }

  &--task,
  &--friends {
    --v-grid-template-columns: 16px 1fr;
    --v-grid-gap: 4px;
    --v-padding: 2px 6px 2px 4px;

    --v-width-icon: 16px;
    --v-height-icon: 16px;

    --v-font-size: 14px;
    --v-line-height: 142.857%;
  }

  &--ms-success-now {
    --v-grid-template-columns: 32px 1fr;
    --v-grid-gap: 8px;
    --v-padding: 8px 20px 8px 8px;

    --v-width-icon: 32px;
    --v-height-icon: 32px;

    --v-font-size: 16px;
    --v-line-height: 150%;

    --v-font-size-sbtx: 14px;
    --v-line-height-sbtx: 142.857%;

    background: rgba(24, 255, 0, 0.3);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(calc(30px / 2));
    position: relative;
    z-index: 2;
  }

  &--ms-success-tmr {
    --v-grid-template-columns: 24px 1fr;
    --v-grid-gap: 8px;
    --v-padding: 8px 16px 8px 8px;

    --v-width-icon: 24px;
    --v-height-icon: 24px;

    --v-font-size: 12px;
    --v-line-height: 133%;

    --v-font-size-sbtx: 10px;
    --v-line-height-sbtx: 140%;

    opacity: 0.8;
    background: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(calc(30px / 2));
    position: relative;
    z-index: 1;
  }

  &--xs {
    --v-grid-template-columns: 14px 1fr;
    --v-grid-gap: 2px;
    --v-padding: 2px 4px 2px 2px;

    --v-width-icon: 14px;
    --v-height-icon: 14px;

    --v-font-size: 10px;
    --v-line-height: 140%;
  }
}
</style>
