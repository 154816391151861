export function appHeight(name: string) {
  function getHeight() {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty(name, `${vh}px`)
  }

  window.addEventListener('resize', () => {
    getHeight()
  })
  getHeight()
}

export function isOdd(num: number): boolean {
  return Boolean(num % 2)
}

export function nFormatter(num: number) {
  if (!num) return 0

  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'g'
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'm'
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k'
  }
  return num
}

export function isCheckPlatform(platform?: string) {
  return platform === 'android' || platform === 'ios'
}

export function checkPlus(vl?: number | null) {
  if (typeof vl !== 'number') return 0

  return vl < 0 ? vl : `+${vl}`
}
