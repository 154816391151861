import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cs-load" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "cs-load__anm" }

import { useLocale } from '@/services/useLocale'


export default /*@__PURE__*/_defineComponent({
  __name: 'MainLoading',
  setup(__props) {

const { t } = useLocale()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "cs-load__t",
      innerHTML: _unref(t)('main_loading.title')
    }, null, 8, _hoisted_2),
    _createElementVNode("div", {
      class: "cs-load__d",
      innerHTML: _unref(t)('main_loading.description')
    }, null, 8, _hoisted_3),
    _createElementVNode("div", _hoisted_4, [
      _createTextVNode(_toDisplayString(_unref(t)('main_loading.loading')), 1),
      _cache[0] || (_cache[0] = _createElementVNode("span", null, ".", -1)),
      _cache[1] || (_cache[1] = _createElementVNode("span", null, ".", -1)),
      _cache[2] || (_cache[2] = _createElementVNode("span", null, ".", -1))
    ])
  ]))
}
}

})