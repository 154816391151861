import { axios, apiV1Url } from '@/api'

export const api = {
  getResources() {
    return axios.get(`${apiV1Url}inventory/prestige`)
  },
  getSkills() {
    return axios.get(`${apiV1Url}inventory/skills`)
  },
  purchaseSkin(info: { skinId: string }) {
    return axios.post(`${apiV1Url}inventory/purchase-skin`, info)
  },
  selectSkin(info: { skinId: string }) {
    return axios.post(`${apiV1Url}inventory/select-skin`, info)
  },
  purchaseSkill(info: { skillId: string }) {
    return axios.post(`${apiV1Url}inventory/purchase-skill`, info)
  },
}
