import createHmac from 'create-hmac'

export function validateTelegramUserInfo(telegramUserInfo: {
  id?: number
  first_name: string
  last_name: string
  username: string
  language_code: string
}) {
  const botToken = process.env?.TG_BOT_TOKEN || ''

  // Создание строки
  const dataString = Object.keys(telegramUserInfo) // telegramUserInfo - это заполненный объект, который щас всегда точно у нас приходит
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .map((key) => `${key}=${telegramUserInfo[key]}`)
    .sort() // Сортировка по алфавиту
    .join('\n') // Объединение с использованием '\n' в качестве разделителя

  // Создание секретного ключа
  const secretKey = createHmac('sha256', 'WebAppData').update(botToken).digest()

  // Создание HMAC-SHA-256 подписи строки проверки данных
  return createHmac('sha256', secretKey).update(dataString).digest('hex')
}
