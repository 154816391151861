import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "lb-c__vl" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "lb-c__tvl" }


export default /*@__PURE__*/_defineComponent({
  __name: 'LabelCount',
  props: {
  mode: {
    type: String,
    default: 'df',
  },
  currentValue: {
    type: [Number, String],
    default: 0,
  },
  totalValue: {
    type: [Number, String],
    default: 0,
  },
  subTxt: {
    type: String,
    default: '',
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["lb-c cs-df-alc", [`lb-c--${__props.mode}`]])
  }, [
    _createElementVNode("span", _hoisted_1, [
      (__props.subTxt)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(__props.subTxt), 1))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(__props.currentValue), 1)
    ]),
    _createElementVNode("span", _hoisted_3, "/" + _toDisplayString(__props.totalValue), 1)
  ], 2))
}
}

})