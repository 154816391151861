import { ref } from 'vue'
import { useUserProfileStore } from '@/stores/user'

export default function usePopupToShow() {
  const userProfileStore = useUserProfileStore()

  const popupToShow = ref<string | null>(null)
  const openPopup = (namePopup: string) => {
    popupToShow.value = namePopup
    userProfileStore.currentPopupToShow = namePopup
  }
  const hidePopup = () => {
    popupToShow.value = null
    userProfileStore.currentPopupToShow = null
  }

  return {
    popupToShow,
    openPopup,
    hidePopup,
  }
}
