<template>
  <div
    class="cs-mob-100"
    :class="['vmc', `vmc--${mode}`, { 'not-scroll': isScrollWrapper }]"
  >
    <div
      class="vmc__wr cs-h100"
      :class="{ 'cs-scroll box-scroll': isScrollWrapper }"
      :style="{
        '--v-height-btn':
          hasBtnContainerSlot && isVisibleBtn ? `${heightBtn}px` : null,
      }"
    >
      <slot name="content-container"></slot>
    </div>
    <div
      v-if="hasBtnContainerSlot && isVisibleBtn"
      class="vmc__btn"
      :class="{
        'vmc__btn--sticky': btnPositionSticky,
        'vmc__btn--grid': isBtnGrid,
      }"
    >
      <slot name="btn-container"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewMobileContainer',
  props: {
    mode: {
      type: String,
      default: 'df',
    },
    btnPositionSticky: {
      type: Boolean,
      default: false,
    },
    isVisibleBtn: {
      type: Boolean,
      default: true,
    },
    isScrollWrapper: {
      type: Boolean,
      default: false,
    },
    isBtnGrid: {
      type: Boolean,
      default: false,
    },
    heightBtn: {
      type: Number,
      default: 68,
    },
  },
  computed: {
    hasBtnContainerSlot() {
      return !!this.$slots['btn-container']
    },
  },
}
</script>

<style lang="scss" scoped>
.vmc {
  background: var(--v-bg-cn);

  &.not-scroll {
    overflow-x: unset;
    overflow-y: unset;
  }

  &__wr {
    padding: var(--v-padding-cn);

    &.cs-scroll {
      height: calc(100% - var(--v-height-btn));
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  &__btn {
    padding: 12px 20px 36px;
    margin-top: auto;
    background: #000;
    z-index: 2;

    &--sticky {
      position: sticky;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      width: 100%;
    }

    &--grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 8px;
    }
  }

  // mode
  &--df {
    --v-bg-cn: #000;
    --v-padding-cn: 20px;
  }

  &--df-tender {
    --v-bg-cn: linear-gradient(
        180deg,
        rgba(18, 192, 255, 0.4) 0%,
        rgba(18, 192, 255, 0) 100%
      ),
      #000;
    --v-padding-cn: 20px;

    .vmc__btn {
      background: transparent;
    }
  }

  &--df-tender-two {
    --v-bg-cn: linear-gradient(
        180deg,
        rgba(167, 228, 0, 0.4) 0%,
        rgba(167, 228, 0, 0) 100%
      ),
      #000;
    --v-padding-cn: 20px;

    .vmc__btn {
      background: transparent;
    }
  }
}
</style>
